


.invisible_file_input {
  visibility: hidden;
  height:1px; 
}

.native-textarea { 
  min-height:60px;
}


ion-input.align-right {
  input[type=text],
  input[type=number] {
    text-align:right;
  }
}


ion-item-group.measure-edit {
  --padding-top: 20px;
  --padding-bottom: 20px;
}


.upload-button {
  flex-basis:100%;
}


.date-details {
  flex-basis: 60px;
  line-height:0.9em;
  font-size:9px;
  padding:5px 0px;
  flex-grow:0;
  align-self:flex-start;

  &__block {
    width: 60px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;

    &--plan {
      background-color: #316b3b;
      color: white;
    }

    &--plan-entered {
      background-color: #335239;
      color: white;
    }


    &--journal {

    }

    &--journal-entered {
      background-color:#bfbfbf;
    }
  }

  &__line1 { 
    font-size:9px;
  }
  &__line2 {
    font-weight:bold;
    font-size:16px;
  }
  &__line3 {
    font-size:14px;
  }

  &__tag { 
    background-color: #eee;
    color: #333;
    padding: 0px 10px;
    font-size: 8px;
    box-shadow: 2px 2px rgba(100,100,100,0.4);
  }

}

.journal-edit {
  width:100%;
  min-height:5px;
  padding:5px;
  border:0px;
  outline:none;

  &:active, &:focus { outline:none; border:0px }
}

.item .journal-details {
  white-space:normal;
}